function themeItOnTime() {
  const timeNow = new Date().toLocaleTimeString("de", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });
  const timeNum = parseInt(timeNow, 10);

  if (timeNum > 18 || timeNum < 9) {
    document.body.classList.add("dark-mode");
  }
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    document.body.classList.add("dark-mode");
  }
}

themeItOnTime();
